import { builder, Builder, withChildren } from '@builder.io/react'
import dynamic from 'next/dynamic'
builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY)
import Button from '~/components/UI-Kit/Button'
import { colors } from '../../tailwindBaseConfig'

Builder.registerComponent(Button, {
  name: 'Core:Button',
  override: true,
  inputs: [
    {
      name: 'text',
      type: 'text',
      defaultValue: 'Click me!',
      bubble: true,
    },
    {
      name: 'link',
      type: 'url',
      bubble: true,
    },
    {
      name: 'openLinkInNewTab',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Open link in new tab',
    },
    {
      name: 'openReportsModal',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Open Reports Modal',
    },
    {
      name: 'variant',
      type: 'string',
      defaultValue: 'primary',
      enum: ['primary', 'secondary', 'text'],
    },
    {
      name: 'radius',
      type: 'string',
      defaultValue: 'none',
      enum: ['none', 'small', 'medium', 'large', 'full'],
    },
    {
      name: 'size',
      type: 'string',
      defaultValue: 'sm',
      enum: ['sm', 'md', 'lg', 'xl'],
    },
    { name: 'backgroundColor', type: 'color' },
    { name: 'textColor', type: 'color' },
  ],
})

Builder.registerComponent(
  dynamic(() => import('~/components/UI-Kit/Icons')),
  {
    name: 'Icons',
    inputs: [
      {
        name: 'icon',
        type: 'string',
        enum: [
          'action-copy',
          'action-download-pdf',
          'action-favorite',
          'action-log-out',
          'action-settings',
          'action-upload',
          'amenities-education',
          'amenities-firepit',
          'amenities-fireplace',
          'amenities-gym',
          'amenities-laundry',
          'amenities-noise',
          'amenities-parking',
          'amenities-pool',
          'amenities-trash',
          'amenities-wifi',
          'communication-phone',
          'communication-question',
          'documents-clipboard',
          'documents-disclosure',
          'documents-flag',
          'documents-inspec',
          'documents-inspection',
          'documents-insurance',
          'documents-permit',
          'documents-prepurchase',
          'documents-repairs',
          'documents-report',
          'documents-title',
          'financial-pay',
          'financial-payment',
          'financial-payment-method',
          'financial-purchase',
          'financial-secure-cc',
          'financial-secure-payment',
          'forms-date',
          'forms-email',
          'forms-name',
          'forms-search',
          'forms-www',
          'home-building',
          'home-exterior',
          'home-foundation',
          'home-garage',
          'home-interior',
          'home-items-dresser',
          'home-listing-status',
          'home-neighborhood',
          'home-rental-listing',
          'home-roof',
          'home-roof-v2',
          'home-use',
          'lens-buyer-lg',
          'lens-buyer-sm',
          'lens-inspector-lg',
          'lens-inspector-sm',
          'lens-insurance-lg',
          'lens-insurance-sm',
          'navigational-account',
          'navigational-ascending',
          'navigational-caret-down',
          'navigational-caret-left',
          'navigational-caret-right',
          'navigational-caret-up',
          'navigational-descending',
          'navigational-expand',
          'navigational-external-link',
          'navigational-hidden',
          'navigational-menu',
          'navigational-navigate',
          'navigational-tooltip',
          'navigational-visible',
          'outdoors-acres',
          'outdoors-eco',
          'outdoors-frontyard',
          'outdoors-outdoors',
          'risk-tsunami-lg',
          'risk-tsunami-sm',
          'risks-contamination-lg',
          'risks-contamination-sm',
          'risks-crime-lg',
          'risks-crime-sm',
          'risks-drought-lg',
          'risks-drought-sm',
          'risks-earth-disturbance-lg',
          'risks-earth-disturbance-sm',
          'risks-earthquake-lg',
          'risks-earthquake-sm',
          'risks-fire-protection-lg',
          'risks-fire-protection-sm',
          'risks-flooding-lg',
          'risks-flooding-sm',
          'risks-hail-lg',
          'risks-hail-sm',
          'risks-health-safety-lg',
          'risks-health-safety-sm',
          'risks-hurricane-lg',
          'risks-hurricane-sm',
          'risks-lightning-lg',
          'risks-lightning-sm',
          'risks-pollution-lg',
          'risks-pollution-sm',
          'risks-sinkhole-lg',
          'risks-sinkhole-sm',
          'risks-thunderstorm-lg',
          'risks-thunderstorm-sm',
          'risks-tornado-lg',
          'risks-tornado-sm',
          'risks-volcano-lg',
          'risks-volcano-sm',
          'risks-weather-and-natural-disasters-lg',
          'risks-weather-and-natural-disasters-sm',
          'risks-wildfire-lg',
          'risks-wildfire-sm',
          'risks-wind-lg',
          'risks-wind-sm',
          'risks-winter-lg',
          'risks-winter-sm',
          'rooms-attic',
          'rooms-baby-room',
          'rooms-basement',
          'rooms-bathroom',
          'rooms-bedroom',
          'rooms-gameroom',
          'rooms-kitchen',
          'rooms-living-room',
          'space-boundary',
          'space-expansion',
          'space-lot',
          'space-map',
          'space-sq-ft',
          'space-stories',
          'subsections-detailed-history',
          'subsections-estimated-utility',
          'subsections-exposure-weather',
          'subsections-exterior-condition',
          'subsections-flood-risk-lg',
          'subsections-flood-risk-sm',
          'subsections-health-safety-lg',
          'subsections-health-safety-sm',
          'subsections-home-care-services',
          'subsections-home-warranties',
          'subsections-homeowner-service-history',
          'subsections-inspection-history',
          'subsections-insuring-property',
          'subsections-listing-details',
          'subsections-modifications',
          'subsections-ownership',
          'subsections-permit-history',
          'subsections-possible-contamination',
          'subsections-property-details',
          'subsections-risks-glance',
          'subsections-spotlight',
          'subsections-terms-definitions',
          'systems-ac',
          'systems-all',
          'systems-electrical',
          'systems-heating',
          'systems-plumbing',
        ],
      },
      {
        name: 'size',
        type: 'string',
        defaultValue: 'md',
        enum: ['sm', 'md', 'lg', 'xl', 'xxl'],
      },
      { name: 'color', type: 'color' },
    ],
  }
)

const MapSearch = dynamic(() => import('~/components/MapSearch'))

Builder.registerComponent(MapSearch, {
  name: 'MapSearch',
  inputs: [
    {
      friendlyName: 'Redirect URL',
      helperText:
        'Sends submission to another page. Use when CheckoutManager does not exist on the same page.',
      name: 'redirectUrl',
      type: 'string',
    },
    {
      name: 'inputLabel',
      type: 'string',
    },
    {
      name: 'defaultAddress',
      type: 'string',
    },
    {
      name: 'buttonLabel',
      type: 'string',
    },
    {
      name: 'modalHeader',
      type: 'string',
    },
    {
      name: 'modalDescription',
      type: 'string',
    },
    {
      name: 'modalConfirmButtonLabel',
      type: 'string',
    },
    {
      name: 'modalCancelButtonLabel',
      type: 'string',
    },
  ],
})

const CheckoutManager = dynamic(() => import('~/components/CheckoutManager'))
const CheckoutManagerWithChildren = withChildren(CheckoutManager)

Builder.registerComponent(CheckoutManagerWithChildren, {
  name: 'CheckoutManager',
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Interstitial content',
      helperText:
        'Reference the interstitial section content to be shown while the report preview API call is made.',
      name: 'interstitial',
      type: 'reference',
      model: 'interstitial',
      required: true,
    },
    {
      friendlyName: 'Minimum interstitial time (ms)',
      helperText:
        'The minimum amount of time the interstitial loading state will be shown. It may be shown longer if the API call exceeds this time.',
      name: 'minimumInterstitialTime',
      type: 'number',
    },
    {
      friendlyName: 'Variants',
      helperText:
        'The variants that are available to purchase on this page. SKUs must be valid OMS SKUs.',
      name: 'variants',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'SKU',
          name: 'sku',
          type: 'reference',
          model: 'sk-us',
          required: true,
        },
      ],
    },
    {
      friendlyName: 'Demographic Checkbox Options',
      helperText:
        'Configure the checkboxes to be shown above the quantity selection.',
      name: 'demographicCheckboxOptions',
      type: 'list',
      subFields: [
        {
          name: 'slug',
          type: 'string',
        },
        {
          name: 'label',
          type: 'string',
        },
      ],
    },
  ],
})

const LandingPageHeroBackground = dynamic(
  () => import('~/components/LandingPageHeroBackground')
)

Builder.registerComponent(LandingPageHeroBackground, {
  name: 'LandingPageHeroBackground',
  noWrap: true,
  inputs: [
    {
      name: 'heroBackgrounds',
      type: 'json',
      description:
        'Enter a JSON array for hero backgrounds. Example: [{"sourceId": "id1", "imageUrl": "url1.jpg"}]',
    },
  ],
})

const LandingPageHero = dynamic(() => import('~/components/LandingPageHero'))

Builder.registerComponent(LandingPageHero, {
  name: 'LandingPageHero',
  inputs: [
    {
      name: 'heroData',
      type: 'json',
      description:
        'Enter a JSON array for hero data. Example: [{"sourceId": "walls_talk-001", "title": "Buying a house?", "headline": "<p>If those walls could talk...</em></strong></p>"}]',
    },
  ],
})

const PrimaryButton = dynamic(() => import('~/components/PrimaryButton'))

Builder.registerComponent(PrimaryButton, {
  name: 'PrimaryButton',
  inputs: [
    {
      name: 'buttonLabel',
      type: 'string',
      defaultValue: 'Buy Now $99 (or bundle & SAVE!)',
    },
  ],
})

function createColors(colors) {
  const asdf = []
  for (const [key, value] of Object.entries(colors)) {
    asdf.push({
      name: key,
      value: `var(--theme-ui-colors-${key}, ${value})`,
    })
  }
  return asdf
}

Builder.register('editor.settings', {
  allowOverridingTokens: true,
  designTokensOptional: true,
  designTokens: {
    colors: createColors(colors),
    spacing: [
      { name: 'Large', value: 'var(--space-large, 20px)' },
      { name: 'Small', value: 'var(--space-small, 10px)' },
      { name: 'Tiny', value: '5px' },
    ],
    fontFamily: [
      {
        name: 'Raleway',
        value: 'Raleway, -apple-system, Roboto, Helvetica, sans-serif',
      },
      {
        name: 'Inter',
        value: 'Inter, -apple-system, Roboto, Helvetica, sans-serif',
      },
    ],
  },
})

// linear-gradient(49deg, var(--token-4ab49e58-a945-4490-8b5c-02ec027b9db5, rgb(10, 148, 239)) 0%, rgb(225, 240, 250) 100%);
// rgb(10, 148, 239)
// rgb(225, 240, 250)

// 'pl-main-brand': '#4494D0',
// 'pl-blue-gray': '#767676',
// 'pl-border-gray': '#E1E3EA',
// 'pl-dark': '#050A30',
// 'pl-gray': '#858585',
// 'pl-gray-background': '#F9F9F9',
// 'pl-gray-light': '#E0E0E0',
// 'pl-gray-medium': '#979797',
// 'pl-gray-white': '#FFFFFF',
// 'pl-light-blue': '#DFF2FE',
// 'pl-rating-green': '#3CC32E',
// 'pl-rating-light-green': '#98DD60',
// 'pl-rating-orange': '#FF7342',
// 'pl-rating-red': '#FF1616',
// 'pl-rating-yellow': '#FFC700',
// 'pl-rating-secondary-blue': '#F0F9FF',
// 'pl-rating-secondary-green': '#E3F6D4',
// 'pl-rating-secondary-orange': '#FDEBE3',
// 'pl-rating-secondary-red': '#FFF5F8',
// 'pl-rating-secondary-yellow': '#FFF8DD',
// 'pl-stytch-white': '#E5E7EB',
