import { createContext, useContext, useEffect, useState } from 'react'
import { useIsPreviewing } from '@builder.io/react'
import { useCheckout as useChordCheckout } from '@chordcommerce/react-autonomy'
import { PropsOnlyChildren } from '~/types'
import {
  CHECKOUT_DATA_KEY_ADDRESS,
  CHECKOUT_DATA_KEY_REPORT,
  CHECKOUT_STEP_DEFAULT,
  CHECKOUT_STEP_INTERSTITIAL,
  CHECKOUT_STEP_CHECKOUT
} from '~/utils/constants'
import { getParamObject } from '~/utils/url'
import { Address } from '~/types';

interface CheckoutContextProps {
  address: any
  isLoading: boolean
  prepareCheckout: () => any
  report: any
  step: string | null
  setAddress: (show: Address | null) => void
  setIsLoading: (show: boolean) => void
  setReport: (show: any | null) => void
  setStep: (show: string | null) => void
}

const CheckoutContext = createContext<CheckoutContextProps>({
  address: null,
  isLoading: false,
  prepareCheckout: () => {},
  report: null,
  step: null,
  setAddress: () => {},
  setIsLoading: () => {},
  setReport: () => {},
  setStep: () => {},
})

export const CheckoutProvider: React.FC<PropsOnlyChildren> = ({ children }) => {
  const { prepareCheckout } = useChordCheckout()
  const isPreviewing = useIsPreviewing()
  const [address, setAddress] = useState<Address | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [report, setReport] = useState<any | null>(null)
  const [step, setStep] = useState<string | null>(CHECKOUT_STEP_INTERSTITIAL)
  
  // Pulls address and/or report from local storage on page load.
  useEffect(() => {
    const storedAddressData = localStorage.getItem(CHECKOUT_DATA_KEY_ADDRESS)
    if (storedAddressData && !isPreviewing) {
      setAddress(JSON.parse(storedAddressData))
    }

    const storedReportData = localStorage.getItem(CHECKOUT_DATA_KEY_REPORT)
    if (storedReportData && !isPreviewing && window.location.pathname !== '/' ) {
      setReport(JSON.parse(storedReportData))
    }

    // Handles addresses passed via MapSearch's redirectUrl option.
    const urlAddress: any = getParamObject('address')
    if (urlAddress && !address) {
      setAddress(urlAddress)
    }
  }, []) // eslint-disable-line

  // Persists address to local storage.
  useEffect(() => {
    if (address && !localStorage.getItem(CHECKOUT_DATA_KEY_ADDRESS)) {
      localStorage.setItem(CHECKOUT_DATA_KEY_ADDRESS, JSON.stringify(address))
    } else if (!address && localStorage.getItem(CHECKOUT_DATA_KEY_ADDRESS)) {
      // testing removing so refresh works
      localStorage.removeItem(CHECKOUT_DATA_KEY_ADDRESS)
    }
  }, [address])

  // Persists report to local storage.
  useEffect(() => {
    if (report && !localStorage.getItem(CHECKOUT_DATA_KEY_REPORT)) {
      localStorage.setItem(CHECKOUT_DATA_KEY_REPORT, JSON.stringify(report))
    } else if (!report && localStorage.getItem(CHECKOUT_DATA_KEY_REPORT)) {
      localStorage.removeItem(CHECKOUT_DATA_KEY_REPORT)
    }
  }, [report])

  // Keeps step in sync with available data.
  useEffect(() => {
    if (!address && !report) {
      setStep(CHECKOUT_STEP_DEFAULT)
    }

    if (address && !report) {
      setStep(CHECKOUT_STEP_INTERSTITIAL)
    }

    if (report) {
      setStep(CHECKOUT_STEP_CHECKOUT)
    }
  }, [address, report])

  return (
    <CheckoutContext.Provider
      value={{
        address,
        isLoading,
        prepareCheckout,
        report,
        step,
        setAddress,
        setIsLoading,
        setReport,
        setStep,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => useContext(CheckoutContext)
