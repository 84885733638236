import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'theme-ui';
import { NextUIProvider } from '@nextui-org/react';
import {
  ModalContextProvider,
  MyReportsContextProvider,
  GetReportsModal,
} from '@propertylens/ui-kit';

import { useStytchUser } from '@stytch/nextjs';
import '../global.css';

import Layout from '~/components/Layout';
import PageTracker from '~/components/PageTracker';
// import ConsentManagerWrapper from '~/components/Segment/ConsentManager'
import ChordProvider from '~/context/chord';

import English from '../locales/en.json';
import theme from '../src/theme';

import '@builder.io/widgets/dist/lib/builder-widgets-async';

const App = ({ Component, pageProps }: AppProps) => {
  const { locale, defaultLocale, push } = useRouter();
  const [shortLocale] = locale ? locale.split('-') : ['en'];

  const messages = useMemo(() => {
    switch (shortLocale) {
      case 'en':
        return English;
      default:
        return English;
    }
  }, [shortLocale]);

  return (
    <ChordProvider pageProps={pageProps}>
      <Script
        id="segment-snippet"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};;analytics.SNIPPET_VERSION="4.13.2";
          analytics.load('${process.env.SEGMENT_WRITE_KEY}');
          }}();`,
        }}
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAGS_ID}');
        `,
        }}
      />
      {/* <!-- Google Tag for GA4 --> */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
      ></Script>
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={messages}
      >
        <NextUIProvider navigate={push}>
          <ThemeProvider theme={theme}>
            <ModalContextProvider>
              <MyReportsContextProvider>
                <PageTracker>
                  <Layout {...pageProps}>
                    {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      @ts-ignore */}
                    <Component {...pageProps} />
                    {process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY && (
                      <GetReportsModal
                        navigate={push}
                        googleAPIKey={
                          process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
                        }
                        authProvider={useStytchUser}
                      />
                    )}
                  </Layout>
                </PageTracker>
              </MyReportsContextProvider>
            </ModalContextProvider>
          </ThemeProvider>
        </NextUIProvider>
      </IntlProvider>
    </ChordProvider>
  );
};

export default App;
